<template>
  <div>
    <div id="particles-js"></div>
    <div class="loginBox">
      <!-- <h5>企业微信扫码登录</h5> -->
      <div id="wx_reg" v-show="!isSuper"></div>
      <!-- <p>扫一扫</p> -->
      <!-- <el-button type="primary" style="width:100%" @click="login">登录</el-button> -->
      <div v-show="isSuper">
        <el-form style="width: 350px" label-position="left">
          <el-form-item label="用户名">
            <el-input
              type="text"
              v-model="username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              type="password"
              v-model="password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="login"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-button type="text" @click="changeLogin">{{
        isSuper ? "扫码登录" : "管理员登录"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { superLogin, getUserInfo, Vertify } from "@/api/api";
import md5 from 'md5-js'
export default {
  data() {
    return {
      isSuper: false,
      username: "",
      password: "",
    };
  },
  mounted() {
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "409EFF",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 5,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#409EFF",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "repulse",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
      config_demo: {
        hide_card: false,
        background_color: "#b61924",
        background_image: "",
        background_position: "50% 50%",
        background_repeat: "no-repeat",
        background_size: "cover",
      },
    });
    let redirect_uri =
      "https://" +
      document.domain +
      (location.port ? ":" + location.port : "") +
      "/#/login";
    window.WwLogin({
      id: "wx_reg",
      appid: process.env.VUE_APP_APPID,
      agentid: process.env.VUE_APP_AGENT,
      redirect_uri: encodeURIComponent(redirect_uri),
      state: "",
      href: "",
    });
    this.init();
  },
  watch: {
    $route() {
      this.init();
    },
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    superLogin() {},
    changeLogin() {
      this.isSuper = !this.isSuper;
    },
    login() {
      superLogin({
        username: this.username,
        password: md5(this.password),
      }).then((res) => {
        sessionStorage.setItem("token", res.data.data.token);
        sessionStorage.setItem("name", res.data.data.user.name);
        sessionStorage.setItem("id", res.data.data.user.id);
        this.$store.commit("setName", res.data.data.user.name);
        this.$router.push("/");
      });
    },
    async init() {
      let code = this.$route.query.code;
      console.log(this.$route.query,"-----route")
      if (code) {
        let res = await getUserInfo({ code });
        if (res.data.code == -1) {
          this.$message.error(res.data.msg);
        } else {
          if (!res.data.data.powerUserIds) {
            this.$store.commit("role", "super");
            sessionStorage.setItem("role", "super");
          }
          
          if (res.data.data.user.role == 0) {
            this.$message.error("您当前不是管理员");
          } else {
            sessionStorage.setItem("name", res.data.data.user.name);
            sessionStorage.setItem("token", res.data.data.token);
            this.$store.commit("setName", res.data.data.user.name);
            this.$router.push("/");
          }
        }
      } else {
        if (sessionStorage.getItem("token")) {
        } else {
          this.$store.commit("setName", "");
          this.$store.commit("role", "");
          sessionStorage.setItem("token", "");
          //this.$router.replace("/login");
        }
      }
    },
  },
};
</script>

<style scoped>
#particles-js {
  width: 100vw;
  height: 100vh;
  /* background-color: #409EFF; */
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.loginBox {
  background: #fff;
  border-radius: 12px;
  width: 400px;
  padding: 40px 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 5px #d4e9ff;
}
.loginBox h5 {
  text-align: center;
  font-size: 24px;
  color: #090808;
}
.loginBox p {
  text-align: center;
  color: rgba(49, 27, 27, 0.44);
}
</style>